
/* --------------------------------------------------------------------------------------
   full-screen-modal.component.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Component, OnInit, Input } from '@angular/core';
import { SessionService } from 'src/app/services';
import { Subject } from 'rxjs';

declare function ExitCUIMode()

@Component({
  selector: 'app-full-screen-modal',
  templateUrl: './full-screen-modal.component.html',
  styleUrls: ['./full-screen-modal.component.scss']
})
export class FullScreenModalComponent implements OnInit {

  @Input() showTitle: boolean = false;
  @Input() showDescription: boolean = false;
  @Input() webservicesDisabled: boolean = false;
  @Input() showEmailNotConfig = false;
  @Input() title: string;
  @Input() description: string;
  @Input() showClose: boolean = false;
  @Input() showOk: boolean = false;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit() {
  }

  /**
   * This method is used to close the app
   */
  exitApp(): void {
    try {
      ExitCUIMode()
    } catch {
      console.log("Exit CUI embedded function not found, switching to empty state instead")
      window.location.href = "about:blank"
    }
  }

  /**
   * This method is called after clicking ok button
   */
  onSubmit() {
    this.sessionService.onSubmitNotification.next()
  }
}
