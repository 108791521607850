
/* --------------------------------------------------------------------------------------
   copy.service.ts
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { SnmpService } from './snmp.service';
import { PwaSessionService, SessionService } from 'src/app/services';
import { oidCodes } from './constants/oid-codes.const';
import { EnvironmentService } from 'src/app/services/environment.service';
declare function xrxCopyInitiateCopyJob(url: string, ticket: string, callback: any, errorCallback: any, timeout: number) : any;
declare function xrxCopyContinueSideTwoCopyJob(url: string, jobId: string, ticket: string, callback: any, errorCallback: any, timeout: number) : any;
declare function xrxCopyGetCopyJobDetails(url: string, jobId: string, callback: any, errorCallback: any, timeout: number) : any;
declare function xrxCopyParseGetCopyJobDetails(response: string) : any;
declare function xrxCopyParseInitiateCopyJob(response: any) : any;
declare function xrxCopyCancelCopyJob(url: string, jobId: string, callback: any, errorCallback: any, timeout: number) : any;

@Injectable({
  providedIn: 'root'
})
export class CopyService {

  quantity: string
  outputColor: string
  inputSides: string
  outputSides: string
  inputTrayName: string
  stapleFinishing: string
  inputOrientation: string
  copyTemplate: any
  finalCopyTicket: any
  deviceUrl: any
 
  constructor(private pwaSessionService: PwaSessionService,
    private sessionService: SessionService,
    private snmpService: SnmpService,
    private environmentService: EnvironmentService) {
      this.deviceUrl = this.sessionService.deviceUrl
     }

  copy(deviceUrl: string, jobTemplate: any) {

    this.quantity = jobTemplate.quantity;
    this.outputColor = jobTemplate.outputColor;
    this.inputSides = jobTemplate.inputSides;
    this.outputSides = jobTemplate.outputSides;
    this.inputTrayName = jobTemplate.inputTrayName;
    this.stapleFinishing = jobTemplate.stapleFinishing;

    if (this.pwaSessionService.isFujiDevice) {
      // In the Fuji controller device, if the output is Two-sided, we need to set the appropriate output values.
      switch (this.outputSides) {
        case "TwoSided":
          this.outputSides = "TwoSidedLongEdge"
          break
        case "SecondSideRotation":
          this.outputSides = "TwoSidedShortEdge"
          break
        default:
          break
      }

      this.finalCopyTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
        '&lt;CopyJobTicket xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://schemas.xerox.com/enterprise/eipjobmodel/1"&gt;' +
        '&lt;JobProcessing&gt;' +
        '&lt;Input&gt;&lt;Sides&gt;' + this.inputSides + '&lt;/Sides&gt;&lt;Darkness&gt;0&lt;/Darkness&gt;&lt;OriginalType&gt;&lt;DocumentImageMode&gt;PhotoAndText&lt;/DocumentImageMode&gt;&lt;/OriginalType&gt;&lt;AutoExposure&gt;Auto&lt;/AutoExposure&gt;&lt;/Input&gt;&lt;Output&gt;&lt;Finishings&gt;&lt;StapleFinishing&gt;' + this.stapleFinishing + '&lt;/StapleFinishing&gt;&lt;PunchFinishing&gt;None&lt;/PunchFinishing&gt;&lt;FoldFinishing&gt;None&lt;/FoldFinishing&gt;&lt;/Finishings&gt;&lt;ColorEffectsType&gt;' + this.outputColor + '&lt;/ColorEffectsType&gt;&lt;Copies&gt;' + this.quantity + '&lt;/Copies&gt;&lt;Sides&gt;' + this.outputSides + '&lt;/Sides&gt;&lt;InputTraysCol&gt;&lt;InputTrayName&gt;' + this.inputTrayName + '&lt;/InputTrayName&gt;&lt;/InputTraysCol&gt;&lt;/Output&gt;' +
        '&lt;/JobProcessing&gt;' +
        '&lt;/CopyJobTicket&gt;'

      return this.initiateCopyJob(deviceUrl, this.finalCopyTicket)
    } else {

      this.snmpService.snmpGetResponse(deviceUrl, oidCodes.paperInPlaten);

      if (!this.sessionService.isPaperinPlaten) { //paper in feeder

        //Feeder job ticket
        this.finalCopyTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
          '&lt;CopyJobTicket xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://schemas.xerox.com/enterprise/eipjobmodel/1"&gt;' +
          '&lt;JobProcessing&gt;' +
          '&lt;Input&gt;&lt;Sides&gt;' + this.inputSides + '&lt;/Sides&gt;&lt;Darkness&gt;0&lt;/Darkness&gt;&lt;OriginalType&gt;&lt;DocumentImageMode&gt;PhotoAndText&lt;/DocumentImageMode&gt;&lt;/OriginalType&gt;&lt;AutoExposure&gt;Auto&lt;/AutoExposure&gt;&lt;Saturation&gt;0&lt;/Saturation&gt;&lt;/Input&gt;&lt;Output&gt;&lt;Finishings&gt;&lt;StapleFinishing&gt;' + this.stapleFinishing + '&lt;/StapleFinishing&gt;&lt;PunchFinishing&gt;None&lt;/PunchFinishing&gt;&lt;FoldFinishing&gt;None&lt;/FoldFinishing&gt;&lt;/Finishings&gt;&lt;ColorEffectsType&gt;' + this.outputColor + '&lt;/ColorEffectsType&gt;&lt;Copies&gt;' + this.quantity + '&lt;/Copies&gt;&lt;Sides&gt;' + this.outputSides + '&lt;/Sides&gt;&lt;InputTraysCol&gt;&lt;InputTrayName&gt;' + this.inputTrayName + '&lt;/InputTrayName&gt;&lt;/InputTraysCol&gt;&lt;/Output&gt;' +
          '&lt;/JobProcessing&gt;' +
          '&lt;/CopyJobTicket&gt;'

        return this.initiateCopyJob(deviceUrl, this.finalCopyTicket)
      } else {
        if (this.outputSides == "TwoSided") { // In the Alexandra device, if the output is Two-sided, we need to set Orientation to "Portrait".
          this.inputOrientation = "Portrait"
          this.finalCopyTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
            '&lt;CopyJobTicket xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://schemas.xerox.com/enterprise/eipjobmodel/1"&gt;' +
            '&lt;JobProcessing&gt;' +
            '&lt;Input&gt;&lt;Sides&gt;' + this.inputSides + '&lt;/Sides&gt;&lt;Darkness&gt;0&lt;/Darkness&gt;&lt;OriginalType&gt;&lt;DocumentImageMode&gt;PhotoAndText&lt;/DocumentImageMode&gt;&lt;/OriginalType&gt;&lt;AutoExposure&gt;Auto&lt;/AutoExposure&gt;&lt;Saturation&gt;0&lt;/Saturation&gt;&lt;LayoutAdjustment&gt;&lt;InputOrientation&gt;' + this.inputOrientation + '&lt;/InputOrientation&gt;&lt;/LayoutAdjustment&gt;&lt;/Input&gt;&lt;Output&gt;&lt;Finishings&gt;&lt;StapleFinishing&gt;' + this.stapleFinishing + '&lt;/StapleFinishing&gt;&lt;PunchFinishing&gt;None&lt;/PunchFinishing&gt;&lt;FoldFinishing&gt;None&lt;/FoldFinishing&gt;&lt;/Finishings&gt;&lt;ColorEffectsType&gt;' + this.outputColor + '&lt;/ColorEffectsType&gt;&lt;Copies&gt;' + this.quantity + '&lt;/Copies&gt;&lt;Sides&gt;' + this.outputSides + '&lt;/Sides&gt;&lt;InputTraysCol&gt;&lt;InputTrayName&gt;' + this.inputTrayName + '&lt;/InputTrayName&gt;&lt;/InputTraysCol&gt;&lt;/Output&gt;' +
            '&lt;/JobProcessing&gt;' +
            '&lt;/CopyJobTicket&gt;'
          return this.initiateCopyJob(deviceUrl, this.finalCopyTicket)
        } else {

          if (this.inputSides == "TwoSided" && this.outputSides == "OneSided") { // In the Alexandra device, when the paper in palten, if the input is Two-sided, we need to set input side to "OneSided".
            this.inputSides = "OneSided";
          }

          this.finalCopyTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
            '&lt;CopyJobTicket xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://schemas.xerox.com/enterprise/eipjobmodel/1"&gt;' +
            '&lt;JobProcessing&gt;' +
            '&lt;Input&gt;&lt;Sides&gt;' + this.inputSides + '&lt;/Sides&gt;&lt;Darkness&gt;0&lt;/Darkness&gt;&lt;OriginalType&gt;&lt;DocumentImageMode&gt;PhotoAndText&lt;/DocumentImageMode&gt;&lt;/OriginalType&gt;&lt;AutoExposure&gt;Auto&lt;/AutoExposure&gt;&lt;Saturation&gt;0&lt;/Saturation&gt;&lt;/Input&gt;&lt;Output&gt;&lt;Finishings&gt;&lt;StapleFinishing&gt;' + this.stapleFinishing + '&lt;/StapleFinishing&gt;&lt;PunchFinishing&gt;None&lt;/PunchFinishing&gt;&lt;FoldFinishing&gt;None&lt;/FoldFinishing&gt;&lt;/Finishings&gt;&lt;ColorEffectsType&gt;' + this.outputColor + '&lt;/ColorEffectsType&gt;&lt;Copies&gt;' + this.quantity + '&lt;/Copies&gt;&lt;Sides&gt;' + this.outputSides + '&lt;/Sides&gt;&lt;InputTraysCol&gt;&lt;InputTrayName&gt;' + this.inputTrayName + '&lt;/InputTrayName&gt;&lt;/InputTraysCol&gt;&lt;/Output&gt;' +
            '&lt;/JobProcessing&gt;' +
            '&lt;/CopyJobTicket&gt;'

          return this.initiateCopyJob(deviceUrl, this.finalCopyTicket)
        }
      }
    }
    
  }
  copySideTwo(jobId: string) {
    //In the Alexandra device, Copy Two-Sided job output ticket
    this.finalCopyTicket = '&lt;?xml version="1.0" encoding="utf-8"?&gt;' +
    '&lt;CopyJobTicket xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://schemas.xerox.com/enterprise/eipjobmodel/1"&gt;' +
    '&lt;JobProcessing&gt;' +
    '&lt;Output&gt;&lt;Sides&gt;' + this.outputSides + '&lt;/Sides&gt;&lt;/Output&gt;' +
    '&lt;/JobProcessing&gt;' +
    '&lt;/CopyJobTicket&gt;';

    return this.continueSideTwoCopyJob(this.deviceUrl, this.finalCopyTicket, jobId)
  }
  initiateCopyJob(deviceUrl: string, jobTemplate: string) : Observable<any> {
    const result =  new Promise((resolve, reject) => {
      xrxCopyInitiateCopyJob(
        deviceUrl,
        jobTemplate,
        (env, response) => resolve(xrxCopyParseInitiateCopyJob(response)),
        (env, error) => reject(error),
        this.environmentService.timeOutValue
      )
    })
    return from(result)
  }
  continueSideTwoCopyJob(deviceUrl: string, jobTemplate: string, jobId: string) : Observable<any> {
    const result =  new Promise((resolve, reject) => {
      xrxCopyContinueSideTwoCopyJob(
        deviceUrl,
        jobId,
        jobTemplate,
        (env, response) => resolve(response),
        (error) => reject(error),
        this.environmentService.timeOutValue
      )
    })
    return from(result)
  }

  /* Not using this function */
  GetCopyJobDetails(deviceUrl: string, jobId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      xrxCopyGetCopyJobDetails(
        deviceUrl,
        jobId,
        (env, response) => resolve(xrxCopyParseGetCopyJobDetails(response)),
        (error) => reject(error),
        this.environmentService.timeOutValue
      )
    })
  }
  cancelCopyJob(jobId: string) {
    const result = new Promise((resolve, reject) => {
      xrxCopyCancelCopyJob(
        this.deviceUrl,
        jobId,
        (env, response) => resolve(response),
        (error) => reject(error),
        this.environmentService.timeOutValue
      )
    })
    return from(result)
  }
}
